@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: 'Lato-Regular';
  src: url('./assets/fonts/Lato/Lato-Regular.woff2');
}

@font-face {
  font-family: 'Lato-Black';
  src: url('./assets/fonts/Lato/Lato-Black.woff2');
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('./assets/fonts/Lato/Lato-Bold.woff2');
}

@font-face {
  font-family: 'Lato-Light';
  src: url('./assets/fonts/Lato/Lato-Light.woff2');
}

body {
  font-family: 'Lato-Light', sans-serif !important;
  background-color: #f9f9f9;
  min-height: 100% !important;

  min-width: auto !important;
}

.h100 {
  height: 100%;
}

.font-white {
  color: #ffffff;
}

.modal-dialog {
  max-width: 780px !important;
}

.blue-curve-background {
  background: url('./assets/safesante-telemedecine.png');
  background-repeat: no-repeat;
  background-size: 100% 5rem;
  background-color: #f8f8f8;
}

.background1 {
  background-image: url('./assets/safesante-telemedecine.png');
  background-size: 100% 100%;
}

.color-white {
  color: #fff !important;
}

.backgroundwhite {
  background-color: #fff;
}

.hasicon a {
  color: #4e57f3;
  font-weight: bold;
  border: 1px solid #4e57f3;
  border-radius: 6px;
  padding: 0.5rem 2rem !important;
}

.mat-radio-button {
  margin: 0 12px;
}

#easyrtcErrorDialog {
  display: none !important;
}

.mt-7r {
  margin-top: 7rem;
}

.mainheading {
  font-size: 30px;
  font-weight: 900;
}

.w165 {
  width: 165px;
}

.pr175 {
  padding-right: 175px;
}

.pin {
  width: 192px;
}

.pr161 {
  padding-right: 192px;
}

.h62 {
  height: 62px;
}

.vcenter {
  display: inline-grid;
  align-items: center;
  float: none;
}

.btn-purple {
  background: #4e57f3;
  color: #fff;
}

.search-form {
  position: relative;
}

.search-form .btn-purple {
  box-shadow: 0px 9px 12px #999fff;
}

.search-back {
  position: absolute;
  z-index: 0;
  left: -48px;
  top: -64px;
}

.posz {
  position: relative;
  z-index: 2;
}

.pos-ab {
  position: absolute;
}

.pos1 {
  left: 12%;
  bottom: 10%;
}

.pos2 {
  left: 15%;
  top: 18%;
}

.pos3 {
  right: 8%;
  top: 16%;
}

.pos4 {
  right: 10%;
  bottom: 12%;
}

.pos5 {
  right: 8%;
  bottom: 10%;
}

.h-400px {
  height: 400px;
}

.invalid-mat-element * {
  caret-color: #f44336;
  color: #f44336;
}

.pointer {
  cursor: pointer;
}

.font-blue {
  color: #4e57f3;
}

/* custom dropdown*/

.anchor-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.round_shadow_box {
  color: rgb(255, 255, 255);
  text-shadow: 0 1px 0 #fff;
  text-align: center;
  text-decoration: none;
  display: block;
  padding: 0 5px;
  margin: 3px auto 5px;
  border: 1px solid rgb(112, 231, 118);
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
}

.background-green {
  background: rgb(59, 185, 91);
}

.background-red {
  background: rgb(160, 10, 35);
}

.background-yellow {
  font-weight: bold;
  color: black;
  background: rgb(229, 245, 8);
}

.qrcode img {
  margin: 25px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.material-icons.color_blue {
  color: #4e57f3 !important;
}

.icon-scale-3 {
  transform: scale(3);
}

.icon-scale-4 {
  transform: scale(4);
}

.icon-scale-2 {
  transform: scale(3);
}

.wrapper-dropdown-3 .dropdown {
  /* Size & position */
  position: absolute;
  top: 140%;
  left: 0;
  right: 0;
  /* Styles */
  background: white;
  border-radius: inherit;
  border: 1px solid rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-weight: normal;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  list-style: none;
  /* Hiding */
  opacity: 0;
  pointer-events: none;
  padding-left: 0;
}

.wrapper-dropdown-3 .dropdown:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 15px;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: #fff transparent;
}

.wrapper-dropdown-3 .dropdown:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 13px;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1) transparent;
}

.wrapper-dropdown-3 .dropdown li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #8aa8bd;
  border-bottom: 1px solid #e6e8ea;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 1);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.wrapper-dropdown-3 .dropdown li i {
  float: right;
  color: inherit;
}

.wrapper-dropdown-3 .dropdown li:first-of-type a {
  border-radius: 7px 7px 0 0;
}

.wrapper-dropdown-3 .dropdown li:last-of-type a {
  border: none;
  border-radius: 0 0 7px 7px;
}

/* Hover state */

.wrapper-dropdown-3 .dropdown li:hover a {
  background: #f3f8f8;
}

/* Active state */

.wrapper-dropdown-3.active .dropdown {
  opacity: 1;
  pointer-events: auto;
}

/* No CSS3 support */

.no-opacity .wrapper-dropdown-3 .dropdown,
.no-pointerevents .wrapper-dropdown-3 .dropdown {
  display: none;
  opacity: 1;
  /* If opacity support but no pointer-events support */
  pointer-events: auto;
  /* If pointer-events support but no pointer-events support */
}

.no-opacity .wrapper-dropdown-3.active .dropdown,
.no-pointerevents .wrapper-dropdown-3.active .dropdown {
  display: block;
}

/*end of custom dropdown*/

/*custom checkbox*/

.lblwrap {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.lblwrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */

.lblwrap:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.lblwrap input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.lblwrap input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.lblwrap .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.width-unset {
  width: unset;
}

.purplecheck input:checked ~ .checkmark {
  background-color: #4e57f3;
}

.greencheck input:checked ~ .checkmark {
  background-color: rgba(59, 237, 223, 0.8);
}

.pac-container {
  z-index: 10000 !important;
  position: relative !important;
  top: 5px !important;
  left: 0px !important;
}

/* DEMO 5 */

.wrapper-dropdown-5 {
  position: relative;
  width: 200px;
  margin: 0 auto;
  padding: 5px 15px;
  background: transparent;
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #fff;
}

.wrapper-dropdown-5:after {
  /* Little arrow */
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -3px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #fff transparent;
}

.wrapper-dropdown-5 .dropdown {
  position: absolute;
  top: 150%;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: none;
  border-bottom: none;
  list-style: none;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  z-index: 9;
}

.wrapper-dropdown-5 .dropdown li {
  padding: 0 10px;
}

.wrapper-dropdown-5 .dropdown li a {
  display: block;
  text-decoration: none;
  color: #333;
  padding: 10px 0;
  transition: all 0.3s ease-out;
  border-bottom: 1px solid #e6e8ea;
}

.wrapper-dropdown-5 .dropdown li:last-of-type a {
  border: none;
}

.wrapper-dropdown-5 .dropdown li i {
  margin-right: 5px;
  color: inherit;
  vertical-align: middle;
}

/* Hover state */

.wrapper-dropdown-5 .dropdown li:hover a {
  color: #57a9d9;
}

/* Active state */

.wrapper-dropdown-5.active {
  border-radius: 5px 5px 0 0;
  background: transparent;
  box-shadow: none;
  border-bottom: none;
  color: white;
}

.wrapper-dropdown-5.active:after {
  border-color: #82d1ff transparent;
}

.wrapper-dropdown-5.active .dropdown {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  max-height: 400px;
  overflow: visible;
}

.wrapper-dropdown-5 .dropdown::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 44%;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: rgba(255, 255, 255) transparent;
}

/*end of custom checkbox*/

.background-grey {
  background-color: #c7c7d3;
}

.border-grey {
  border: 1px solid #e5e5e5;
}

.green-btn {
  background: #15dbcd;
  border-color: #15dbcd;
}

.cp {
  cursor: pointer;
}

.user-name {
  color: #000000;
  font-size: 34px;
  font-weight: 900;
}

.user-sub-name {
  color: #8793a1;
  font-size: 18px;
}

.ds {
  display: table;
}

.ds li {
  display: table-cell;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: #4e57f3;
  border-bottom: 1px solid #4e57f3;
  border-radius: 0;
}

.nav-pills .nav-link:hover {
  color: #4e57f3;
  border-bottom: 1px solid #4e57f3;
  border-radius: 0;
}

.nav-pills .nav-link {
  color: #8793a1;
}

.ds {
  display: table;
  border-bottom: 1px solid #e9e9e9;
}

.section-title {
  color: #8793a1;
}

.color87 {
  color: #8793a1;
}

.font20 {
  font-size: 20px;
}

.bold {
  font-weight: bold;
}

.dropdown4 {
  border: 1px solid #e6e6e6;
  width: 90%;
  color: #000;
  padding: 10px 15px;
  margin: 0;
}

.dropdown4::after {
  border-color: #e6e6e6 transparent;
}

.dropdown4.active {
  color: #000;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 6px;
  padding: 10px 15px;
}

.dropdown4 .dropdown {
  top: 150%;
  box-shadow: 6px 3px 37px rgba(0, 0, 0, 0.25);
}

.d-table {
  display: table;
}

.d-table-cell {
  display: table-cell;
}

.background-light-blue {
  background-color: #f4f8fe;
}

.date-table th {
  font-size: 12px;
  text-align: center;
  font-weight: 400;
}

.date-table th .font20 {
  font-weight: bold;
}

.popwrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.overflow-y {
  overflow-y: auto;
}

.purplebackground {
  background-color: #4e57f3 !important;
  border-color: #4e57f3 !important;
}

.greenbackground {
  background-color: #15dbcd !important;
  border-color: #15dbcd !important;
}

.redbackground {
  background-color: #d16153 !important;
  border-color: #d16153 !important;
}

.purplelightbackground {
  background-color: #4e57f3 !important;
  border-color: #4e57f3 !important;
}

.color-light-blue {
  color: #8793a1;
}

.purple-color {
  color: #4e57f3;
}

.backgroundgrey {
  background: #f8f8f8;
}

.border-r-5 {
  border-radius: 5px;
}

.pos-rel {
  position: relative;
}

.edit {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 12px;
}

.edit img {
  max-width: 14px;
}

/*custom switch*/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e7f0fc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 30px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #4e57f3;
}

.switch input:checked + .slider {
  background-color: #e7f0fc;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider::before {
  -webkit-transform: translateX(2px);
  -ms-transform: translateX(2px);
  transform: translateX(2px);
  background: #4e57f3;
  box-shadow: 0px 5px 6px #999fff;
  left: 4px;
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*end of custom switch*/

.color-black {
  color: #000;
}

.menu-white {
  color: #fff !important;
  border-color: #fff !important;
}

.font50 {
  font-size: 50px;
}

.font46 {
  font-size: 46px;
}

.font36 {
  font-size: 36px;
}

.font34 {
  font-size: 34px;
}

.font18 {
  font-size: 18px;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}

.font12 {
  font-size: 12px;
}

.font11 {
  font-size: 11px;
}

.ls1 {
  letter-spacing: 1px;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.font22 {
  font-size: 22px;
}

.color-grey {
  color: #5e6670;
}

.font28 {
  font-size: 28px;
}

.navbar-toggler {
  border: 1px solid #ccc;
  width: 46px;
  height: 32px;
  display: none;
}

.bar {
  height: 2px;
  width: 100%;
  display: block;
  background: #ccc !important;
  float: left;
  margin: 2px 0;
}

.leftarrow {
  position: absolute;
  left: 4px;
  top: 44px;
}

.rightarrow {
  position: absolute;
  right: 4px;
  top: 44px;
}

.date-table td span.dt img {
  position: absolute;
  right: 6px;
  top: 6px;
}

.font-weight400 {
  font-weight: 400 !important;
}

.fw700 {
  font-weight: 700;
}

.fw300 {
  font-weight: 300;
}

.back-h-510 {
  background-size: 100% 410px;
}

.w-300p {
  width: 300px;
}

.dropdown-display-label::after,
.dropdown-display::after {
  border-top: none !important;
}

.dropdown-display-label {
  border: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 15px !important;
}

.dropdown-chose-list {
  /* border-left: 1px solid #4e57f3; */
  color: #4e57f3 !important;
  /* padding-left: 20px !important; */
}

.dropdown-display-label .dropdown-search {
  display: block;
  margin: 50px 0 20px;
  border-top: 1px solid #f5f5f5;
  padding-top: 28px;
}

.dropdown-search {
  /* border-left: 1px solid #4e57f3; */
  padding-left: 20px;
  display: block !important;
}

.dropdown-selected {
  background: #f8f8f8;
  padding: 10px 28px !important;
  border: none !important;
}

.dropdown-selected .del {
  top: 10px !important;
  position: relative;
  right: 10px !important;
  /* font-size: 16px !important; */
}

.dropdown-multiple-label.active .dropdown-display-label::after,
.dropdown-multiple-label.active .dropdown-display::after,
.dropdown-multiple.active .dropdown-display-label::after,
.dropdown-multiple.active .dropdown-display::after,
.dropdown-single.active .dropdown-display-label::after,
.dropdown-single.active .dropdown-display::after {
  border-bottom: none !important;
}

.dropdown-display-label {
  width: 100%;
}

.dropdown-chose-list {
  width: 100%;
}

.dropdown-search input {
  border-left: 1px solid #4e57f3 !important;
  padding-left: 20px !important;
}

.st {
  background: #f8f8f8;
  padding: 6px 20px;
  display: inline-block;
  font-size: 12px;
}

.nonp {
  background: #ffdbdf;
  border-radius: 12px;
  padding: 3px;
  font-size: 11px;
  margin-left: 10px;
  margin-right: 10px;
  padding-right: 15px;
}

.bg {
  background: #d7eee9;
}

.cad {
  margin: 13px;
}

.squarebtn {
  background: #4e57f3;
  color: #fff;
  border-radius: 5px;
  border: navajowhite;
  height: 46px;
  width: 46px;
  margin-left: -15px !important;
  box-shadow: 0px 9px 12px #999fff;
}

.medin {
  height: 45px;
}

.mdd {
  height: 45px;
  margin-bottom: 0px !important;
  line-height: 20px;
}

.ptb {
  padding-top: 3px;
  padding-bottom: 8px;
}

.medi-search {
  border-top: 1px solid #f8f8f8;
  padding-top: 22px;
}

.greenspan {
  background: #d7eee9;
  padding: 6px 15px;
  border-radius: 12px;
  color: #45b49c;
  font-size: 12px;
  padding-right: 28px;
}

.btn285-purple {
  border-radius: 27px;
  width: 285px;
  height: 54px;
  color: #4e57f3;
  border: 1px solid #4e57f3;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  text-align: center;
  box-shadow: 0px 9px 12px #999fff;
}

.btn285-grey {
  border-radius: 27px;
  width: 285px;
  height: 54px;
  border: 1px solid #e5e5e5;
  color: #8793a1;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  text-align: center;
  box-shadow: 0px 8px 15px #e1e8f1;
}

.checkmark.size21 {
  width: 21px;
  height: 21px;
}

.checkmark.size21::after {
  top: 3px;
}

.purple-shadow {
  box-shadow: 0px 9px 12px #999fff !important;
}

.scroll {
  max-height: 310px;
  overflow: auto;
}

.progress-bar {
  background-color: #4e57f3 !important;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: #ccc;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  /* background-color: rgba(0,0,0,0.2); */
}

.mCSB_outside + .mCSB_scrollTools {
  right: -10px;
}

.mr5 {
  margin-left: -5px;
  margin-right: -5px;
}

.plr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.border-radius5 {
  border-radius: 5px;
}

.spnc {
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: 10px;
  min-width: 133px;
  display: inline-block;
  font-size: 18px;
  margin-right: 10px;
  padding-right: 30px;
}

.abcross {
  position: absolute;
  right: 10px;
  top: 18px;
}

.h140p {
  height: 140px;
  overflow-y: auto;
}

.background-transparent {
  background: transparent;
}

.smimg {
  height: 30px;
}

.snspan.spr {
  background: #ffdbdf;
  color: #f34257;
  font-size: 11px;
  padding: 3px 10px;
  border-radius: 12px;
}

.snspan.spg {
  background: #d7eee9;
  color: #45b49c;
  font-size: 11px;
  padding: 3px 10px;
  border-radius: 12px;
}

.abs-red {
  position: absolute;
  height: 9px;
  width: 9px;
  background: #f84675;
  border-radius: 14px;
  top: 0;
  right: -9px;
}

.max-w-686p {
  max-width: 686px;
}

.greenback {
  border-radius: 3px;
  background-color: #15dbcd;
  color: #fff;
  margin-left: 10px;
  padding: 3px 20px;
}

.purpleback {
  border-radius: 3px;
  background-color: #4e57f3;
  color: #fff;
  margin-left: 10px;
  padding: 3px 20px;
}

.body-back-f8 {
  background: #f8f8f8;
}

.pnk-btn {
  border-radius: 5px;
  background-color: #f84675;
  box-shadow: 0 2px 5px 0 #ffb3c7;
  color: #fff;
  padding: 10px 40px !important;
}

.white-header {
  background: #fff;
  box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.09);
  position: relative;
  z-index: 1;
}

.img100 {
  width: 100%;
}

.smallimg {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.chat-btn {
  border-radius: 5px;
  background-color: #4e57f3;
  box-shadow: 0 9px 12px 0 #999fff;
  color: #fff;
  padding: 10px 30px;
  border: none;
}

.chat-container .dtable {
  display: table;
  width: 100%;
}

.chat-container .dtable .dtc {
  display: table-cell;
}

.back-white {
  background: #fff;
}

.chat-box {
  position: absolute;
  /* width  : 97%; */
  bottom: 0;
  padding: 20px 0;
  border-top: 1px solid #c7c7d3;
  left: 0;
  right: 0;
}

.chatbtns {
  padding: 30px 20px 5px;
}

.w30 {
  width: 30px;
}

.txt-grey {
  color: #c7c7d3;
}

.chat-txt {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 170px;
  padding: 0 15px;
}

/* .black, .black.active {
color: #000;
} */

.black::after {
  right: 0px;
  border-color: #000 transparent;
}

.navbar-brand {
  float: left;
}

header {
  float: left;
  width: 100%;
  position: relative !important;
  z-index: 9 !important;
}

.wrapper-dropdown-5 img {
  max-width: 26px;
}

.left-calendar .switch {
  height: 28px;
}

.left-calendar .slider::before {
  height: 20px;
  width: 20px;
}

.pad-left300 {
  padding-left: 328px !important;
}

.color-light-grey {
  color: #a9abeb;
}

.font31 {
  font-size: 31px;
}

.table-condensed,
.datepicker.datepicker-inline {
  width: 100% !important;
}

.selecteShipDate {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 40px;
  margin-top: 10px;
  padding-bottom: 40px;
}

.table-condensed th,
.table-condensed td {
  padding: 6px;
  color: #fff;
}

.smbox {
  height: 10px;
  width: 10px;
  margin-top: 3px;
}

.boxyellow {
  background-color: #ffed2b;
}

.boxgreen {
  background-color: #abe38d;
}

.boxblue {
  background-color: #46f0f8;
}

.boxred {
  background-color: #f84675;
}

.filters {
  margin-top: 4rem;
}

.left-calendar td.active {
  background: #fff !important;
  color: #1f5ee0 !important;
}

.left-calendar td:hover {
  background: #fff !important;
  color: #1f5ee0 !important;
}

.fc-left .fc-state-default,
.fc-right .fc-state-default {
  background: #f8f8f8;
  box-shadow: none;
  border: none;
  border-radius: 0;
  margin-right: 15px !important;
  font-size: 12px;
  font-family: Lato-Black;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #8793a1;
  height: auto;
  width: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.fc-left .fc-state-active {
  background: #4e57f3;
  color: #fff;
  text-shadow: none;
  box-shadow: 0 6px 12px 0 #999fff;
  border-radius: 0;
}

.fc-day-header.fc-widget-header {
  border: none;
  padding: 5px;
  font-family: Lato-Regular;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.fc-head-container.fc-widget-header,
.fc-axis.fc-widget-header {
  border: none;
}

div.fc-center h2 {
  font-size: 20px;
  font-family: Lato-Regular;
  font-weight: bold;
}

div.fc-slats tr {
  border-top-style: groove;
}

.fc-minor {
  border-top: none !important;
}

.fc-axis.fc-time.fc-widget-content {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom-style: dotted !important;
  border-width: 1px;
  font-family: Lato-Regular;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  height: 7px;
  line-height: 7px;
}

.fc .fc-axis {
  padding: 7px 17px;
}

.fc-view.fc-agendaWeek-view.fc-agenda-view table {
  border-left: 0 hidden;
}

.fc-event {
  border-left: 2px solid;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-radius: 0;
}

.fc-time-grid-event .fc-time {
  font-family: Lato-Regular;
  font-size: 10px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5e6670;
}

.fc-title {
  font-family: Lato-Regular;
  font-size: 14px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.fc-scroller {
  height: auto !important;
  overflow-y: hidden !important;
}

.overflow-visible {
  white-space: initial;
}

.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.fc-short {
}

.dfull {
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #e5e5e5;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  color: #000;
}

.dfull.wrapper-dropdown-5::after {
  border-color: #000 transparent !important;
}

.dfull.wrapper-dropdown-5.active {
  border-radius: 5px;
  background: #fff;
  box-shadow: none;
  border-bottom: none;
  color: #000;
  border-bottom: 1px solid #ececec;
}

.mt-10 {
  margin-top: 10px;
}

.topm7 {
  top: -7px;
}

.bckicons {
  background-position: 10px 12px;
}

.border-left-5 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.border-right-5 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ptb10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.center-menu li.active a {
  border-bottom: 2px solid #4e57f3;
}

.distable {
  display: table;
}

.distc {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.big-tab-section .big-tab-btns .distc {
  background: #e7e7e7;
  height: 77px;
  padding: 8px;
  font-size: 14px;
}

.big-tab-section .big-tab-btns .distc.active {
  background: #f8f8f8;
}

.distc.bsmtab {
  width: 130px;
}

.rightsmallimg {
  position: absolute;
  bottom: 10px;
  right: 10px;
  max-width: 140px;
}

.margin-left0 {
  margin-left: 0 !important;
}

.h90p {
  height: 90px;
  overflow-y: auto;
}

.smchat {
  height: 500px;
}

.smchat .chat-txt {
  height: 320px;
  overflow: auto;
}

.font24 {
  font-size: 24px;
}

.btn278 {
  width: 278px;
  height: 41px;
  border-radius: 5px;
  background-color: #4e57f3;
  box-shadow: 0 9px 12px 0 #999fff;
}

.big-green-btn {
  border-radius: 5px;
  background-color: #15dbcd;
  box-shadow: 0 9px 12px 0 rgba(31, 141, 133, 0.29);
  color: #fff;
  padding: 10px 40px !important;
}

.w48 {
  max-width: 48px;
}

.font26 {
  font-size: 26px;
}

.h-62 {
  height: 62px;
}

.greydrop {
  background: #fff;
  color: #8793a2;
}

.greydrop {
  background: #fff;
  color: #8793a2;
}

.greydrop.wrapper-dropdown-5::after {
  border-color: #8793a1 transparent;
}

.greydrop span {
  padding-top: 12px;
  display: inline-block;
}

.greydrop.wrapper-dropdown-5.active {
  color: #000;
  background: #fff;
}

.mt-m {
  margin-top: -32px;
}

.pt-c {
  padding-top: 32px;
}

.red-color {
  color: #f84675;
}

.formdrop,
.formdrop.active {
  border: 1px solid #ced4da !important;
  color: #000 !important;
}

.formdrop span {
  padding-top: 10px;
  display: inline-block;
}

.formdrop::after,
.formdrop .dropdown::before {
  border-color: #000 transparent;
}

.formdrop.wrapper-dropdown-5.active .dropdown {
  border-top: 1px solid #dbdbdb;
}

.mt-m50 {
  margin-top: -50px;
}

.lightpurplebackground {
  background-color: #999fff;
  box-shadow: 0 9px 12px 0 #999fff;
  width: 100%;
  border: none;
}

.span-grey {
  border-radius: 12px;
  background: #8793a1;
  color: #ffffff;
  font-weight: 900;
  font-size: 11px;
  padding: 4px 15px;
}

/* .blue {
	position: absolute;
	top: 17%;
	right: 7%;
} */

/* .green {
	position: absolute;
	bottom: 10%;
	left: 1%;
} */

.light-blue {
  position: absolute;
  left: 10%;
  bottom: 20%;
}

.bluep5 {
  position: absolute;
  top: 15%;
  right: 5%;
}

.greenp5 {
  position: absolute;
  left: 2%;
  top: 56%;
}

.light-bluep5 {
  position: absolute;
  left: 11%;
  top: 46%;
}

.date-table thead tr th {
  border-top: none !important;
}

.top2 {
  top: 15px !important;
}

.twodotcenter {
  position: relative;
  top: 5px;
}

.opacity-half {
  opacity: 0.5;
}

.disn {
  display: none;
}

.disb {
  display: inline;
}

.w300p {
  width: 300px;
}

.ncht {
  position: absolute;
  right: 0;
  top: 0;
}

.smwm .w-75 {
  width: 70% !important;
}

.smwm .w-75 li {
  width: 33.33% !important;
}

.boxsm .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #eee;
}

.boxsm {
  padding-left: 26px;
}

.lblwrap.boxsm .checkmark::after {
  left: 3px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.chky .checkmark {
  background-color: #ffed2b;
}

.lblwrap.chky input:checked ~ .checkmark {
  background-color: #ffed2b;
}

.lblwrap.chky input:checked ~ .checkmark::after {
  border-color: #000;
}

.chkg .checkmark {
  background-color: #abe38d;
}

.lblwrap.chkg input:checked ~ .checkmark {
  background-color: #abe38d;
}

.lblwrap.chkg input:checked ~ .checkmark::after {
  border-color: #000;
}

.chkb .checkmark {
  background-color: #46f0f8;
}

.lblwrap.chkb input:checked ~ .checkmark {
  background-color: #46f0f8;
}

.lblwrap.chkb input:checked ~ .checkmark::after {
  border-color: #000;
}

.chkr .checkmark {
  background-color: #f84675;
}

.lblwrap.chkr input:checked ~ .checkmark {
  background-color: #f84675;
}

.lblwrap.chkr input:checked ~ .checkmark::after {
  border-color: #fff;
}

@media print {
  #printContainer > div {
    display: inline;
    overflow-y: visible;
  }

  body {
    min-width: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .mainheading {
    font-size: 32px;
    font-weight: 900;
    padding-top: 14%;
  }

  .ip100 {
    width: 100% !important;
  }
}

@media (min-width: 300px) and (max-width: 992px) {
  div.fc-center h2 {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-toggler.collapsed {
    display: none;
  }

  .navbar-collapse {
    /* display: block !important; */
    /* float: right; */
  }
}

@media (max-width: 767px) {
  .rightarrow {
    right: -10px;
  }

  .navbar-nav {
    box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.15);
    background: #fff !important;
    position: absolute;
    z-index: 2000;
    padding-bottom: 5px;
    width: 200px;
    right: 0;
  }

  .navbar-toggler {
    display: block;
    float: right;
  }

  .navbar-collapse.show {
    float: left;
    width: 100%;
    box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.15);
    background: #fff;
  }

  .mzi {
    z-index: 99;
  }

  .navbar-brand {
    float: left;
  }

  .navbar-collapse {
    width: 100%;
  }

  .pr161 {
    padding-right: 84px;
  }

  .navbar-nav li {
    text-align: right;
    margin: 0 !important;
    width: 100%;
    padding: 15px;
    padding-right: 1.5rem !important;
  }

  .nav-link {
    font-size: 12px;
  }

  .hasicon {
    margin-left: 15px !important;
  }

  .hasicon a {
    padding: 0.5rem 1rem !important;
  }

  header {
    text-align: center;
  }

  .mainheading {
    font-size: 20px;
    font-weight: 900;
    color: #000000;
  }

  .search-input-wrap {
    float: left;
    width: 100%;
    padding: 0px;
    margin-top: -250px;
  }

  .mobile-adjust {
    width: 100% !important;
    margin-top: 130px;
    position: relative;
    margin-bottom: 9%;
  }

  .wrapper-dropdown-3 {
    width: 100%;
  }

  .pin {
    width: 100%;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .search-input.pr161 {
    padding: 0;
    float: left;
    width: 100%;
  }

  .pos2 {
    top: 90px;
  }

  .pos3 {
    right: 8%;
    top: 80px;
    max-width: 48px;
  }

  .pos1 {
    max-width: 50px;
  }

  .search-back {
    left: -38px;
    top: 0px;
    max-width: 70px;
  }

  .h100 {
    /* height: auto; */
    min-height: 100%;
    float: left;
    width: 100%;
  }

  /* .pl-4{
	padding-left: 0.5rem !important;
}
.pr-4 {
padding-right: 0.5rem !important;
} */
  .seaarch-input {
    padding-right: 90px;
  }

  .mobilepad0 {
    padding: 0;
  }

  .mp10 {
    padding: 10px !important;
  }

  .mm0 {
    margin: 0 !important;
  }

  .blue-curve-background {
    background-size: 100% 300px;
    float: left;
    width: 100%;
  }

  .mfn {
    float: none !important;
  }

  .popwrap .h100 {
    height: 100%;
  }

  .medin {
    height: 45px;
    width: 90%;
    margin: 10px auto;
  }

  .back-h-510 {
    background-size: 100% 472px !important;
  }

  .no-menu .navbar-brand {
    float: none;
  }

  .mmt20 {
    margin-top: 20px !important;
  }

  .mfont30 {
    font-size: 32px;
  }

  .mimg {
    max-width: 90px;
  }

  .mfont24 {
    font-size: 24px;
  }

  .mdd {
    width: 90%;
    margin-left: 16px;
  }

  .squarebtn {
    margin-left: 16px !important;
    margin-top: 10px;
  }

  .mgen {
    float: left;
    width: 100%;
  }

  .mgen1 {
    float: none;
    width: 100%;
  }

  .mcolor .wrapper-dropdown-5 {
    color: #444 !important;
  }

  .mcolor .wrapper-dropdown-5::after {
    border-color: #747474 transparent;
  }

  .white-header {
    float: left;
    width: 100%;
  }

  .chat-container .dtable,
  .chat-container .dtc {
    display: block !important;
    width: 100% !important;
  }

  .chat-box,
  .chat-txt {
    position: initial;
  }

  .chat-box {
    margin-top: 20px;
  }

  .chat-txt {
    max-height: 200px;
    overflow: auto;
  }

  .container-fluid {
    float: left;
  }

  .content-wrap {
    float: left;
    width: 100%;
  }

  .content-wrap1 {
    float: none;
    width: 100%;
  }

  .edit {
    top: 0px;
  }

  .w-300p {
    max-width: 100%;
  }

  .mtop15 {
    top: 15px;
  }

  .smallimg {
    max-width: 100px;
  }

  .mflt {
    float: left;
    width: 100%;
  }

  .left-calendar {
    position: relative;
    float: left;
    width: 100%;
  }

  .right-content {
    float: left;
    width: 100%;
    padding: 0 !important;
  }

  .right-content .p-5 {
    padding: 20px 15px !important;
  }

  .fc-left .fc-state-default {
    width: 55px;
    margin-bottom: 10px;
  }

  .filters {
    margin-top: 50px;
  }

  .mmb20 {
    margin-bottom: 20px;
  }

  .mw100 {
    width: 100% !important;
  }

  .mbm .ds {
    display: block !important;
  }

  .mbm .ds li {
    display: block;
    border-bottom: 1px solid #e9e9e9;
  }

  .mp15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .mp0 {
    padding: 0 !important;
  }

  .ban-header {
    padding: 3rem 1rem !important;
  }

  .content-wrap {
    position: relative;
    z-index: 1;
  }

  .light-blue,
  .green {
    z-index: 0;
  }

  .pos1 {
    left: 0%;
    bottom: 0%;
    max-width: 30px !important;
  }

  .pos4 {
    right: 8%;
    bottom: 5%;
    max-width: 30px;
  }

  .pos5 {
    right: 6%;
    bottom: 3%;
    max-width: 20px;
  }

  .nav-link.color-white {
    color: #000;
  }

  .bluep5 {
    position: absolute;
    top: 8%;
    right: 11%;
    max-width: 40px;
    z-index: 0;
  }

  .menu-white {
    color: #000 !important;
    border-color: #4e57f3 !important;
  }

  .disn {
    display: inline;
  }

  .disb {
    display: none;
  }

  .date-table td span {
    padding: 8px 20px !important;
  }

  .mobcb,
  .mobcb.active {
    color: #000;
  }

  .mobcb.wrapper-dropdown-5::after {
    border-color: #000 transparent;
  }

  .mob-border {
    border: 1px solid #ccc;
  }

  .menus .nav-link {
    padding: 5px;
  }

  .btn285-purple {
    width: 100%;
  }

  .btn285-grey {
    width: 100%;
  }

  .w300p {
    width: 100%;
  }

  /* .mmt20{margin-top:20px !important;}
.mpt20{padding-top:20px !important;} */
  .mmr5 {
    margin-right: 5px !important;
  }

  .ncht {
    position: relative;
    right: 0;
    top: 0;
    padding-top: 70px;
  }

  .mobmax150 {
    max-width: 120px;
  }

  .mobup {
    position: absolute;
    top: -10px;
    right: 0;
    z-index: 9;
  }

  .smwm .w-75 li {
    width: 100% !important;
  }

  .mobtopm74 {
    position: relative;
    top: -74px;
  }

  .mobtop74 {
    position: relative;
    top: 74px;
  }

  .ldc {
    background-size: 169% 236px;
  }
}

button {
  cursor: pointer;
}

.chat-me,
.chat-user {
  margin-bottom: 20px;
}

.text-front {
  position: relative;
  z-index: 9;
}

.p-shadow {
  box-shadow: 0 9px 12px 0 #999fff;
}

.shadow-none {
  box-shadow: none;
}

@media (min-width: 1024px) {
  .filters {
    margin-top: 4rem;
  }
}

@media (min-width: 1500px) {
  .filters {
    margin-top: 10rem;
  }
}

/* @media(max-width:450px){
.h100 {
height:100%;

float: left;
width: 100%;
}
} */

html,
body {
  height: 100%;
  // max-width: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

body {
  height: auto !important;
}

.modal-dialog {
  max-width: 900px !important;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

.signatureModalClass .modal-dialog {
  max-width: 650px !important;
}

.mat-drawer-content {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .row {
    margin-right: -3px;
    margin-left: -3px;
  }

  .float-right-mobile {
    float: right;
  }

  .zero-margin-mobile {
    margin: 0 !important;
  }
}

.flex-wrap {
  flex-wrap: wrap !important;
}

/****************************************Caro-Styles***************************************/

.no-user {
  width: 120px;
  height: 120px;
  background-color: #c7c7d3;
  text-align: center;
  line-height: 120px;
  color: #ffffff;
  fill: #ffffff;
}

.w-750 .modal-dialog {
  max-width: 800px;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

/* .modal-lg{
height: 90%;
}



app-add-treatment-prescription{
height: 100%;
}

button:focus{
outline: none;
} */

.required {
  border-color: #f84675 !important;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 1300px;
  }
}

.md-modal .modal-dialog {
  min-width: 900px;
}

.header-container {
  font-family: Lato-Regular;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
  background-image: linear-gradient(276deg, #6156d5, #0067ee);
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.warning-header-container {
  background-color: #f84675;
  color: #ffffff;
  font-family: Lato-Bold;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.32;
  letter-spacing: normal;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.white-container {
  background-color: #ffffff;
}

.blue-container {
  background-image: linear-gradient(299deg, #6156d5);
}

.white-container.shadow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 #e2e2e2;
  padding: 20px;
}

.grey-container {
  background-color: #f4f8fe;
  border-radius: 5px;
  border: none;
  padding: 10px;
}

.btn.white {
  font-family: Lato-Black;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #ffffff;
  border: none;
  background-color: transparent;
}

.btn.black {
  font-family: Lato-Black;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #000000;
  border: none;
  background-color: transparent;
}

.btn.turquoise {
  font-family: Lato-Black;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #15dbcd;
  border: none;
  background-color: transparent;
}

.btn.turquoise.fill {
  font-family: Lato-Black;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  background-color: #15dbcd;
  cursor: allowed;
  pointer-events: auto;
  text-transform: uppercase;
  /* box-shadow   : 0 9px 12px 0 #15dbcd; */
}

.btn.grey.fill {
  font-family: Lato-Black;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: grey;
  cursor: not-allowed;
  pointer-events: none;
  text-transform: uppercase;
  /* box-shadow   : 0 9px 12px 0 #15dbcd; */
}

.btn.blue {
  font-family: Lato-Black;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #4e57f3;
  border: none;
  background-color: transparent;
}

.btn.blue.fill {
  font-family: Lato-Black;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  background-color: #4e57f3;
  box-shadow: 0 9px 12px 0 #999fff;
}

.btn.red {
  background-color: transparent;
  border: none;
  font-family: Lato-Black;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #f84675;
}

.btn.red.fill {
  border-radius: 5px;
  background-color: #f84675;
  box-shadow: 0 2px 5px 0 #ffb3c7;
  border: none;
  font-family: Lato-Black;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ffffff;
}

.btn.no-border {
  border: none;
  background-color: transparent;
  font-family: Lato-Black;
  font-size: 11px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.82;
  letter-spacing: 0.9px;
  outline: none;
}
